function getCompassPlusEndpoint(api: string | null): string | undefined {
  if (process.env.REACT_APP_ENV === 'production') {
    return process.env.REACT_APP_API_ENDPOINT;
  }
  // See if there's an API GET parameter and send that instead.
  if (api !== null) {
    const cleansedApi = api.replace(/[^.\-\w\s]/gi, '');
    return `https://${cleansedApi}.careersandenterprise.co.uk`;
  }
  // Finally fallback to the default.
  return process.env.REACT_APP_API_ENDPOINT;
}

export function getValidateAccessTokenEndpoint(api: string | null, accessToken: string | null): string {
  return getCompassPlusEndpoint(api) + '/api/public/validate-access-token?accessToken=' + accessToken;
}

export function getSubmitEndpoint(api: string | null): string {
  return getCompassPlusEndpoint(api) + '/api/public/submit-future-skills-questionnaire';
}

/**
 * Decide if we are sending the security credentials to Compass+.
 * This is used as part of any fetch requests.
 */
export function getIncludeSecurityCredentials(): 'same-origin' | 'include' {
  if (process.env.REACT_APP_ENV === 'production') {
    return 'same-origin';
  }

  return 'include';
}

export function getCompassPlusRequestHeaders(): {} {
  if (process.env.REACT_APP_ENV === 'production') {
    return {
      headers: new Headers({}),
    };
  }

  return {
    headers: new Headers({ credentails: 'include' }),
  };
}

export type ApiResponse = {
  studentName: string;
  isPreview: boolean;
  questionnaire: Questionnaire;
};
export type Questionnaire = {
  id: number;
  type: 'yr7' | 'yr9' | 'ks4' | 'ks5' | 'send';
  questions: Question[];
};
export type Error = {
  errors: string;
  status: number;
  type: string;
  title: string;
};
export type questionTypes =
  | 'SEND1'
  | 'SEND2'
  | 'SEND3'
  | 'SEND4'
  | 'CareersKnowledgeSkills'
  | 'CareersKnowledgeSkillsYear9GCSE'
  | 'EssentialSkills';
export interface Question {
  id: number;
  questionText: string;
  questionSubText: string | null;
  hasFollowUp: boolean;
  followUpQuestion: FollowUpQuestion | null;
  answerFormat: string;
  conditionToShow: QuestionConditions[] | null;
  responseType: {
    id: number;
    type: questionTypes;
    responseOptions: Response[];
  };
  viewId: string;
  mandatory: boolean;
}
export interface QuestionConditions {
  [id: string]: number[]
}
export interface Response {
  id: number;
  text: string;
  triggersFollowUp: boolean;
  emoji: string;
  unique: boolean;
}
export interface FollowUpQuestion {
  text: string;
  mandatory: boolean;
  length: number;
}
export interface Submission {
  accessToken: string;
  questionnaireId: number;
  answers: SubmissionAnswer[];
}

export type SubmissionAnswer = {
  questionId: number;
  responseOptionId: number[];
  textResponse: string;
};

export type SubmissionResponse = {
  code: number;
  message: string;
};

import { ReactElement } from 'react';
import styled from 'styled-components';

const BarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  padding: 14px;
  width: 520px;
  background-color: var(--cec-white);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 850px) {
    width: calc(60% - 44px);
    margin-left: 1rem;
    margin-right: 1rem;
  }
  @media (max-width: 700px) {
    display: none;
  }
`;
const Circle = styled.div<{ $completed: boolean }>`
  width: 20px;
  z-index: 2;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => (props.$completed ? 'var(--cec-yellow)' : 'var(--cec-white)')};
  border: ${(props) => (props.$completed ? 'solid 1px var(--cec-yellow)' : 'solid 1px var(--cec-light-grey)')};
`;
const Line = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 1px;

  background-color: var(--cec-light-grey);
  width: calc(100% - 28px);
  z-index: 1;
`;

export default function ProgressBar({
  currentQuestion,
  totalQuestions,
}: {
  currentQuestion: number;
  totalQuestions: number;
}): ReactElement {
  const total = Array.from(Array(totalQuestions).keys());

  return (
    <BarContainer>
      {total.map((index) => (
        <Circle $completed={index < currentQuestion} key={index} />
      ))}
      <Line></Line>
    </BarContainer>
  );
}

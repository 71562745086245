import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<{ theme: { bg: string } }>`

:root {
  --cec-teal: #00a8a8;
  --cec-blue: #006992;
  --cec-purple: #525e93;
  --cec-yellow: #e8b463;
  --cec-orange: #ed6e4f;
  --cec-pink: #ec5f65;
  --cec-green-light: #0b7976;
  --cec-green: #124f44;
  --cec-dark-grey: #575756;
  --cec-mid-grey: #788685;
  --cec-light-grey: #e0deda;
  --cec-text: #002424;
  --cec-white: #ffffff;
  --cec-teal-tint-90: #19b1b1;
  --cec-teal-tint-80: #34baba;
  --cec-teal-tint-70: #4dc2c2;
  --cec-teal-tint-60: #66cbcb;
  --cec-teal-tint-50: #7fd3d3;
  --cec-teal-tint-40: #98dcdc;
  --cec-teal-tint-30: #b2e4e4;
  --cec-teal-tint-20: #cceeee;
  --cec-teal-tint-10: #e5f6f6;
}

body, html {
  position: relative;
  background-color: ${(props) => props.theme.bg};

  width:100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: 'Lato';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: -0.13px;
  color: var(--cec-white);
}

h2 {
  font-family: 'Lato';
  font-size: 1.5rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
a {
  color: inherit;
}

input,
button,
textarea {
  font-family: 'Lato';
  font-size: 1rem;
  border-radius: 0; /*Safari mobile.*/
  border: 0;
  -webkit-appearance: none;
}

button {
  cursor: pointer;
  margin: 0;
}
`;
export const PreviewBar = styled.div`
  width: 100%;
  padding: 0.5rem 0;
  background-color: red;
  color: white;
  position: sticky;
  text-align: center;
  top: 0;
  z-index: 4;
`;
export const Header = styled.header`
  &::after {
    content: '';
    width: 150%;

    position: absolute;
    background-color: var(--cec-white);
    height: 8.0625rem;
    border-radius: 0 0 50% 50%;
    z-index: 0;
    @media (max-width: 1023px) {
      width: 125%;

      border-radius: 0 0 25% 25%;
    }
  }

  text-align: center;
  width: 100%;

  overflow: hidden;
  height: 8.0625rem;
  position: relative;
  color: black;
  display: flex;
  justify-content: center;
  img {
    position: absolute;
    height: 3.3125rem;
    margin-top: 2.3125rem;
    margin-bottom: 2.4375rem;
    z-index: 3;
  }
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
`;

export const SENDTheme = {
  bg: 'var(--cec-green-light)',
  bar: '#0b797680',
};

export const Section1Theme = {
  bg: 'var(--cec-blue)',
  bar: '#00699280',
};
export const Section2Theme = {
  bg: 'var(--cec-purple)',
  bar: '#525e9380',
};

import styled from 'styled-components';
export const RadioLabel = styled.label<{ icon?: string; iconActive?: string }>`
  cursor: pointer;
  & > input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  & > input:focus-visible + span {
    outline: Highlight auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }
  & > input + span {
    width: 28.1875rem;

    @media (max-width: 1024px) {
      width: calc(100% - 2.5rem);
    }

    display: flex;
    align-items: center;
    padding: 10px 20px 10px 14px;
    border: solid 2px var(--cec-white);
    border-radius: 10px;
    margin-bottom: 8px;
    div {
      flex-grow: 2;
    }
    &::before {
      content: '';
      min-width: 22px;
      min-height: 22px;
      background-color: ${(props) => props.theme.bg};
      border: solid 2px ${(props) => props.theme.bg};
      border-radius: 50%;
      margin-right: 14px;
      box-shadow: inset 0px 0px 0px 2px var(--cec-white);
    }
    &::after {
      content: '';
      display: inline-block;
      mask-image: url(${(props) => props.icon});
      background-color: var(--cec-white);
      mask-repeat: no-repeat;
      mask-position: center;
      object-fit: contain;
      min-width: 48px;
      min-height: 50px;
    }
  }
  @media (hover: hover) {
    &:hover > input + span {
      background-color: var(--cec-white);
      color: ${(props) => props.theme.bg};
      &::before {
        min-width: 20px;
        min-height: 20px;
      }
      &::after {
        content: '';
        display: inline-block;
        mask-image: url(${(props) => props.icon});
        background-color: ${(props) => props.theme.bg};
        mask-repeat: no-repeat;
        mask-position: center;
        object-fit: contain;
        min-width: 48px;
        min-height: 50px;
      }
    }
  }
  & > input:checked + span {
    background-color: var(--cec-white);
    color: ${(props) => props.theme.bg};

    &::before {
      min-width: 20px;
      min-height: 20px;
    }
    &::after {
      background-color: ${(props) => props.theme.bg};
    }
  }
`;

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration({
          levels: ['error'],
      }),
  ],
  environment: process.env.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE || '0.0'),
});
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import styled from 'styled-components';
export const FullWidthWrapper = styled.div`
  width: 100%;
  display: flex;

  justify-content: center;
  margin-top: 2.8125rem;
  flex-direction: column;
  align-items: center;
`;

import { InfoContainer, NextButton, PreviousButton, ButtonWrapper } from '../styles-components';
import Section1Img from './fsq-section-1.svg';
import Section2Img from './fsq-section-1.svg';

export default function SectionDivide({
  onNext,
  onPrevious,
  part,
  isSend,
}: {
  onNext: () => void;
  onPrevious: () => void;
  part: 1 | 2 | 3;
  isSend: boolean;
}) {
  if (part === 1) {
    return (
      <InfoContainer>
        <img src={Section1Img} width="200px" height="186px" alt="people sitting around a table"></img>
        <h2>Part 1: Career Knowledge and Skills</h2>
        <ul>
          <li>These questions ask about your career related knowledge and skills.</li>
          <li>Career describes our journey through life, learning and work.</li>
          <li>Please take the time to read and answer each question carefully.</li>
          <li>Remember, there are no right or wrong answers, so answer as honestly as you can.</li>
        </ul>
        <ButtonWrapper>
          <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>

          <NextButton onClick={onNext}>Get Started</NextButton>
        </ButtonWrapper>
      </InfoContainer>
    );
  }

  if (part === 2 && !isSend) {
      return (
          <InfoContainer>
              <img src={Section2Img} width="193px" height="186px"
                   alt="man with question lightbulb above his head"></img>

              <h2>Part 2: Skills for life and work</h2>
              <ul>
                  <li>
                      {' '}
                      The next eight questions ask about some of the skills you will develop throughout your life.
                      Skills are
                      personal qualities and things someone is able to do.{' '}
                  </li>
                  <li>
                      Remember that there are no right or wrong answers and to take your time to carefully read and
                      answer each
                      question.
                  </li>
              </ul>
              <p>&nbsp;</p>
              <p>We'd like you to think how well you are able to demonstrate each of the following eight skills.</p>
              <ButtonWrapper>
                  <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>
                  <NextButton onClick={onNext}>Get Started</NextButton>
              </ButtonWrapper>
          </InfoContainer>
      );
  }

  if (part === 2 && isSend) {
      return (
          <InfoContainer>
              <img src={Section2Img} width="193px" height="186px"
                   alt="man with question lightbulb above his head"></img>

              <h2>Part 2: Skills for life and work</h2>
              <ul>
                  <li>
                      The next three questions ask about some of the skills you will develop throughout your life.
                  </li>
                  <li>
                      Skills are personal qualities and things someone is able to do.
                  </li>
                  <li>
                      Remember that there are no right or wrong answers and to take your time to carefully read and
                      answer each
                      question.
                  </li>
              </ul>
              <p>&nbsp;</p>
              <p>We'd like you to think how well you are able to demonstrate each of the following skills.</p>
              <ButtonWrapper>
                  <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>
                  <NextButton onClick={onNext}>Get Started</NextButton>
              </ButtonWrapper>
          </InfoContainer>
      );
  }

    return (
      <InfoContainer>
        <img src={Section2Img} width="200px" height="186px" alt="man with question lightbulb above his head"></img>

        <h2>Part 3: Career planning</h2>
        <ul>
            <li>The next two questions are about career planning.</li>
            <li>Remember that there are no right or wrong answers and to take your time to carefully read and answer each question. </li>
        </ul>
        <ButtonWrapper>
          <PreviousButton onClick={onPrevious}>Go Back</PreviousButton>
          <NextButton onClick={onNext}>Get Started</NextButton>
        </ButtonWrapper>
      </InfoContainer>
    );
}

import { InfoContainer } from '../styles-components';
import Error from '../Error';
import SubmitImage from './fsq-party.svg';

export default function Submitted({ status }: { status: 'loading' | 'success' | 'error' | 'ServerSide' | 'preview' }) {
  if (status === 'success') {
    return (
      <InfoContainer>
        <img src={SubmitImage} width="406px" height="278px" alt="people dancing at a party"></img>

        <h2>Thank you for completing this questionnaire.</h2>
        <p>If you have any questions or concerns after completing the questionnaire, please talk to your teacher.</p>
        <p>&nbsp;</p>
        <p>You may now close your browser window.</p>
      </InfoContainer>
    );
  } else if (status === 'loading') {
    return <Error error="Loading"></Error>;
  } else if (status === 'ServerSide') {
    return <Error error="ServerSide"></Error>;
  } else if (status === 'preview') {
    return (
      <InfoContainer>
        <img src={SubmitImage} width="406" height="278" alt="people dancing at a party"></img>

        <h2>Thanks for previewing the questionnaire.</h2>
        <p>&nbsp;</p>
        <p>Your responses will not be saved as this is a preview of the questionnaire.</p>
      </InfoContainer>
    );
  } else {
    return <Error error="PostError"></Error>;
  }
}

import { ReactElement, useState } from 'react';
import { Response, Question as ApiQuestion, SubmissionAnswer } from '../../api';
import Radio from '../Radio';
import Checkbox from '../Checkbox';
import { QuestionContainer, PreviousButton, NextButton } from '../styles-components';
import {
  Footer,
  SmallText,
  Link,
  TextArea,
  BlackText,
  OptionalMessage,
  ChangeWrapper,
  ReadOnlyRadio,
  FixedBar,
  Subtext,
  ItalicSubtext,
} from './styles';
import { imageList } from './images';

export default function Question({
  question,
  onNext,
  onPrevious,
  onSubmit,
  currentQuestion,
  lastQuestion,
  selected,
  reverse,
  children,
  isPreview,
}: {
  onNext: (answer?: SubmissionAnswer) => void;
  question: ApiQuestion;
  onPrevious: (answer?: SubmissionAnswer) => void;
  onSubmit: () => void;
  currentQuestion: number;
  lastQuestion: boolean;
  selected?: SubmissionAnswer;
  reverse: boolean;
  children: ReactElement;
  isPreview: boolean;
}): ReactElement {
  const findResponseFromId = (responseId: number): Response | null =>
    question.responseType.responseOptions.find((response) => response.id === responseId) || null;

  const getTextQuestionInfo = (): Response =>
    question.responseType.responseOptions[0];

  const findEmojiFromId = (responseId: number): string | null =>
    question.responseType.responseOptions.find((response) => response.id === responseId)?.emoji || null;

  const [responseOptionId, setResponseOptionId] = useState<number[] | undefined>(
    selected ? selected.responseOptionId : undefined
  );

  const [textResponse, setTextResponse] = useState<string>(
    selected ? selected.textResponse : ''
  );

  const [followUp, setFollowUp] = useState<boolean>(
    (reverse && question.hasFollowUp && responseOptionId && responseOptionId.map((option, index) => findResponseFromId(option)?.triggersFollowUp).includes(true)) ||
      false
  );

  function next() {
    if (responseOptionId) {
      if (question.hasFollowUp && responseOptionId.map((option, index) => findResponseFromId(option)?.triggersFollowUp).includes(true) && !followUp) {
        setFollowUp(true);
      } else {
        setFollowUp(false);
        onNext({
          textResponse: textResponse,
          questionId: question.id,
          responseOptionId: responseOptionId,
        });
        if (lastQuestion) {
          onSubmit();
        }
      }
    } else if (!question.mandatory){
      setFollowUp(false);
      onNext();
    }
  }
  function prev() {
    if (followUp) {
      setFollowUp(false);
    } else {
      onPrevious(
        responseOptionId
          ? {
              textResponse: textResponse,
              questionId: question.id,
              responseOptionId: responseOptionId,
            }
          : undefined
      );
    }
  }

  return (
    //question.type === SEND2 or EssentialSkills
    //pass in new currentQuestion and no divider
    <>
      <QuestionContainer>
        {/* {question.responseType.type === 'SEND2' || question.responseType.type === 'EssentialSkills' ? (
          <SmallText>Skill {currentQuestion}</SmallText>
        ) : (
          <SmallText>Question {currentQuestion}</SmallText>
        )} */}
        <SmallText>{question.viewId }</SmallText>
        <h2>{question.questionText}</h2>
        {question.questionSubText ? (
          question.responseType.type === 'CareersKnowledgeSkills' && currentQuestion === 3 ? (
            <ItalicSubtext>{question.questionSubText}</ItalicSubtext>
          ) : (
            <Subtext>{question.questionSubText}</Subtext>
          )
        ) : null}
        {/* onSubmit(next()) */}
        <form onSubmit={next}>
          {!followUp && question.answerFormat === 'multiple' &&
            question.responseType.responseOptions.map((option, index) => (
              <Checkbox
                onInput={() => {
                  if (responseOptionId?.includes(option.id)){
                    responseOptionId.splice(responseOptionId.indexOf(option.id), 1);
                    responseOptionId?.length > 0 ? setResponseOptionId([...responseOptionId]) : setResponseOptionId(undefined);
                  }
                  else if (!responseOptionId || option.unique) {
                    setResponseOptionId([option.id]);
                  }
                  else {
                    const uniqueOptions = question.responseType.responseOptions.filter(
                      (option) => option.unique && responseOptionId?.includes(option.id)
                    );
                    uniqueOptions.forEach((option) => responseOptionId.splice(responseOptionId.indexOf(option.id), 1))
                    responseOptionId?.push(option.id);
                    setResponseOptionId([...responseOptionId]);
                  }
                }}
                checked={responseOptionId?.includes(option.id)}
                key={option.id}
              >
                {option.text}
              </Checkbox>
            ))}
          {!followUp && question.answerFormat === 'single' &&
            question.responseType.responseOptions.map((option, index) => (
              <Radio
                onInput={() => {
                  setResponseOptionId([option.id]);
                }}
                onDoubleClick={() => {
                    setResponseOptionId([option.id]);
                    next();
                }}
                checked={responseOptionId?.includes(option.id)}
                icon={imageList[option.emoji] || undefined}
                key={option.id}
              >
                {option.text}
              </Radio>

            ))}
          {!followUp && 'text' === question.answerFormat && (
            <>
              <TextArea
                placeholder={getTextQuestionInfo().text?? ''}
                onChange={(e) => {
                  setTextResponse(e.target.value);
                  setResponseOptionId([getTextQuestionInfo().id])}
                }
                defaultValue={selected ? selected?.textResponse : textResponse}
                maxLength={500}
              ></TextArea>
            </>
          )}
          {followUp && responseOptionId && (
            <>
              <ChangeWrapper>
                <ReadOnlyRadio icon={ findEmojiFromId(responseOptionId[0]) || undefined}>
                  <span>
                    <div>{findResponseFromId(responseOptionId[0])?.text}</div>
                  </span>
                </ReadOnlyRadio>
                <Link
                  onClick={() => {
                    setTextResponse('');
                    setFollowUp(false);
                  }}
                >
                  Change
                </Link>
              </ChangeWrapper>

              {!question.followUpQuestion?.mandatory && (
                <OptionalMessage>
                  {question.followUpQuestion?.text} <BlackText>(Optional)</BlackText>
                </OptionalMessage>
              )}
              {question.followUpQuestion?.mandatory && (
                <p>
                  {question.followUpQuestion.text}
                </p>
              )}
              <TextArea
                maxLength={question.followUpQuestion?.length}
                placeholder="A short description is fine."
                onChange={(e) => setTextResponse(e.target.value)}
                defaultValue={selected ? selected?.textResponse : textResponse}
              ></TextArea>
            </>
          )}
        </form>
      </QuestionContainer>
      <FixedBar>
        <Footer>
          {/* If we're showing the followup then take the user back to the radios? */}
          <PreviousButton onClick={prev}>Previous</PreviousButton>
          {/* If there needs to be a follow up then show it on this button press */}
          {/* on enter key press, assume "next"  */}
          <div>{children}</div>
          <NextButton
            disabled={
              (!responseOptionId && question.mandatory && '' === textResponse ) ||
              (followUp && question.followUpQuestion?.mandatory && !textResponse)
            }
            onClick={next}
          >
            {lastQuestion ? 'Complete Questionnaire' : 'Next'}
          </NextButton>
        </Footer>
      </FixedBar>
    </>
  );
}

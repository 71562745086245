import { ReactElement, ReactNode } from 'react';
import { RadioLabel } from './styles';

export default function Checkbox({
  checked = false,
  onInput,
  children,
}: {
  checked?: boolean;
  icon?: string;
  onInput?: () => void;
  onDoubleClick?: () => void;
  children: ReactNode;
}): ReactElement {
  return (
    <RadioLabel>
      <input type="checkbox" name="radios" checked={checked} onChange={onInput}></input>
      <span>
        <div>{children}</div>
      </span>
    </RadioLabel>
  );
}

import styled from 'styled-components';

export const FixedBar = styled.div`
  position: fixed;
  width: 100%;
  border-top: 1px solid white;
  bottom: 0;
  padding-top: 1rem;
  background-color: ${(props) => props.theme.bar};
`;
export const Footer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  padding: 0 2rem;
  padding-bottom: 2rem;
  box-sizing: border-box;
`;
export const SmallText = styled.p`
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
`;
export const Link = styled.button`
  font-size: 0.875rem;
  text-transform: uppercase;

  &:focus-visible {
    outline: Highlight auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }
  @media (max-width: 450px) {
    padding-left: 0;
  }
  padding-left: 1.125rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  outline: none;
  color: var(--cec-white);
  border: none;
  text-shadow: 0px 0px 0px transparent;
  text-decoration: underline;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  &,
  input {
    /* 1 */
    overflow: visible;
  }
`;
export const TextArea = styled.textarea`
  &:focus-visible {
    outline: Highlight auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }
  @media (max-width: 1024px) {
    width: calc(100% - 2.5em);
  }
  height: 170px;
  width: 28.6875rem;
  font-family: 'Lato';
  border-radius: 10px;
  margin-top: 10px;
  border: solid 2px var(--cec-white);
  padding: 10px 15px 10px 15px;
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
`;

export const BlackText = styled.span`
  color: var(--cec-yellow);
`;

export const ChangeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 568px;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    min-height: 6.25rem;
  }
  height: 80px;
`;
export const OptionalMessage = styled.p`
  margin-top: 2rem;
`;
export const ReadOnlyRadio = styled.div<{ icon: string | undefined }>`
  @media (max-width: 1024px) {
    width: 100%;
  }
  & > span {
    width: 28.1875rem;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 14px;
    border: solid 2px var(--cec-white);
    border-radius: 10px;
    margin-bottom: 8px;
    background-color: var(--cec-white);
    color: ${(props) => props.theme.bg};
    @media (max-width: 1024px) {
      width: calc(100% - 2.5em);
    }
    div {
      flex-grow: 2;
    }
    &::before {
      content: '';
      min-width: 22px;
      min-height: 22px;
      background-color: ${(props) => props.theme.bg};
      border: solid 2px ${(props) => props.theme.bg};
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 14px;
      box-shadow: inset 0px 0px 0px 2px var(--cec-white);
    }
    &::after {
      content: '';
      display: inline-block;
      mask-image: url(${(props) => props.icon});
      background-color: ${(props) => props.theme.bg};
      mask-repeat: no-repeat;
      mask-position: center;
      object-fit: contain;
      min-width: 48px;
      min-height: 50px;
    }
  }
`;
export const Subtext = styled.h2`
  color: var(--cec-yellow);
`;
export const HelpSubtext = styled(Subtext)`
  cursor: help;
`;
export const ItalicSubtext = styled(Subtext)`
  font-size: 1.2rem;
  font-style: italic;
`;

import { ReactElement, ReactNode } from 'react';
import { RadioLabel } from './styles';

export default function Radio({
  checked = false,
  icon,
  onInput,
  onDoubleClick,
  children,
}: {
  checked?: boolean;
  icon?: string;
  onInput?: () => void;
  onDoubleClick?: () => void;
  children: ReactNode;
}): ReactElement {
  return (
    <RadioLabel icon={icon}>
      <input type="radio" name="radios" defaultChecked={checked} onInput={onInput}></input>
      <span onDoubleClick={onDoubleClick}>
        <div>{children}</div>
      </span>
    </RadioLabel>
  );
}

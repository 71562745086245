import DontKnowIcon from './icons/q-icon-i-don-t-know.svg';
import NotYetIcon from './icons/q-icon-no-not-yet.svg';
import PossiblyIcon from './icons/q-icon-possibly.svg';
import YesMajorIcon from './icons/q-icon-yes-major.svg';
import YesMinorIcon from './icons/q-icon-yes-minor.svg';

export type Image = string | null;

export const imageList: {[id: string]: string} = {
  "dontKnow": DontKnowIcon,
  "notYet": NotYetIcon,
  "possiblyIcon": PossiblyIcon,
  "yesMinor": YesMinorIcon,
  "yesMajor": YesMajorIcon,
  "none": "",
};

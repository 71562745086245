import Chevron from './chevron.svg';
import ChevronBack from './chevron-back.svg';
import styled from 'styled-components';

export const QuestionContainer = styled.div`
  width: 489px;
  @media (max-width: 786px) {
    width: 100%;
  }
  @media (max-width: 1023px) {
    width: 80%;
  }

  margin-bottom: 7.125rem;
  form {
    margin-top: 2rem;
  }
`;
export const InfoContainer = styled(QuestionContainer)`
  width: 600px;
  text-align: center;
  margin-top: 4.625rem;


  @media (max-width: 1023px) {
    width: 80%;
    img {
      width: 90%;
    }
  }

  h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  ul {
    li {
      text-align: left;

      list-style-type: disc;
      
      margin-bottom: 0rem;
    }
  }
  button {
    margin-top: 2rem;
  }
  p {
    text-align: left;
  }
`;
export const ErrorContainer = styled(QuestionContainer)`
  text-align: center;
  margin-top: 2rem;

  h2 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }
`;

export const Button = styled.button`
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  border: none;
  display: inline-block;
  padding: 1rem;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 700;
  position: relative;
  transition: transform 0.2s;

  &[disabled] {
    opacity: 0.4;

    cursor: default;
  }
`;
export const NextButton = styled(Button)`
  background-color: var(--cec-yellow);
  display: inline-block;

  &::after {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(${Chevron});
    background-position: center;
    width: 10px;
    height: 14px;
    margin-left: 11px;
    object-fit: contain;
  }
  &[disabled] {
    ::after {
      opacity: 0.4;
    }
  }
  &::before {
    content: '';
    border-radius: inherit;
    opacity: 0.3;
    background-color: inherit;
    position: absolute;
    display: block;
    top: 3px;
    left: 3px;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.2s;
  }
  @media (hover: hover) {
    &:hover {
      transform: translate(-2px, -2px);
      &::before {
        transform: translate(2px, 2px);
      }
    }
  }

  &:focus-visible {
    outline: Highlight auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:active {
    transform: translate(3px, 3px);

    &::before {
      transform: translate(-3px, -3px);
    }
  }
`;
export const PreviousButton = styled(Button)`
  background-color: var(--cec-white);

  &::before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-image: url(${ChevronBack});
    background-position: center;
    width: 10px;
    height: 14px;
    margin-right: 11px;
    object-fit: contain;
  }
  &::after {
    content: '';
    border-radius: inherit;
    opacity: 0.3;
    background-color: inherit;
    position: absolute;
    display: block;
    top: 3px;
    left: 3px;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: transform 0.2s;
  }
  @media (hover: hover) {
    &:hover {
      transform: translate(-2px, -2px);
      transform: translate(2px, 2px);
    }
  }

  &:focus-visible {
    outline: Highlight auto 5px;
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:active {
    transform: translate(3px, 3px);

    &::after {
      transform: translate(-3px, -3px);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CenteredText = styled.p`
  text-align: center;
`;

import { InfoContainer, NextButton } from '../styles-components';
import WelcomeImage from './Initial.svg';
import { HelpSubtext } from '../Question/styles';

export default function Welcome({
  onClick,
  isSend,
  studentName,
}: {
  onClick: () => void;
  isSend: boolean;
  studentName: string;
}) {
  const cecWebUrl = process.env.REACT_APP_CEC_WEB_URL;

  return (
    <InfoContainer>
      <img src={WelcomeImage} width="193px" height="186px" alt="man with question mark above head"></img>
      <h2>Welcome to the Future Skills Questionnaire</h2>
      {studentName ? (
        <HelpSubtext>
          <span title={'Not your name? Speak to your teacher as you may have the incorrect questionnaire link.'}>
            {studentName}*
          </span>
        </HelpSubtext>
      ) : (
        <></>
      )}

      <ul>
        <li>
          Your responses will be viewed by your schools Careers Leader to help shape and evaluate your career programme
          and make sure that you are supported to make informed choices about your next steps after school or college.
        </li>
        <li>
          The questionnaire takes about 10 minutes to complete. There are no right or wrong answers so please answer the
          questions as honestly as you can.
        </li>
          {
            isSend ?
                <li>
                    There are three sections to the questionnaire, the first on your career knowledge and skills, and
                    the second on essential skills for life and work, and the third on career planning.
                </li>
                :
                <li>
                    There are two sections to the questionnaire, the first on your career knowledge and skills, and the
                    second on essential skills for life and work.
                </li>
          }
        <li>
          Your data will be processed in line with the Future Skills Questionnaire{' '}
          <a href={cecWebUrl + "/questionnaire-privacy-notice/"}>privacy notice</a> which tells
          you why and how your personal information is processed.
        </li>
      </ul>

      <NextButton onClick={onClick}>Get Started</NextButton>
    </InfoContainer>
  );
}
